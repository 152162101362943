import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import {RichText, Link} from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import InlineSVG from '../components/inlineSVG'
import Spiral from '../images/spiral.svg'

import '../components/successStory.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

export const query = graphql`
query successStoryQuery($uid: String) {
  prismic {
    allSuccess_storys(uid: $uid) {
      edges {
        node {
          success_story_title
          success_story_sub_title
          success_story_image
          success_story_rich_text
          success_story_testimonial
          success_story_featured
          _linkType
          _meta {
            uid
            type
            tags
          }
          body {
            ... on PRISMIC_Success_storyBodyStats {
              type
              label
              fields {
                stat_title
                stat_icon
              }
            }
          }
          success_story_campaign {
            ... on PRISMIC__ExternalLink {
              target
              _linkType
              url
            }
          }
          success_story_website {
            ... on PRISMIC__ExternalLink {
              target
              _linkType
              url
            }
          }
        }
      }
    }
  }
}
`


const SuccessStory = props => {

  if(!props) return null;
  
  const doc = props.data.prismic.allSuccess_storys.edges[0];

  if(!doc) return null;
  if(!doc.node) return null;

  const story = doc.node;

return (
    <Layout>

    <SEO title="Kickbooster" />

      <div className="kb__container--narrow kb__success-story">
        <div className="kb__grid-middle">
          <div className="kb__col-6_sm-12 kb__success-story-image-wrapper">
            <img className="kb__success-story-image" src={story.success_story_image.square.url} alt={RichText.asText(story.success_story_title)} />
            <InlineSVG url={Spiral} classes="shape" />
          </div>
          <div className="kb__col-6_sm-12">
            <h1>{RichText.asText(story.success_story_title)}</h1>

            {story.success_story_sub_title &&
              RichText.render(story.success_story_sub_title)
            }
          </div>

          {story.success_story_rich_text &&

            <div className="kb__col-12 big-body">
              {RichText.render(story.success_story_rich_text)}
            </div>

          }

          {story.success_story_website &&

            <div className="kb__actions kb--center">
              <a href={RichLink(story.success_story_website)} title="Visit website" className="kb__button--primary kb__button--large">Visit website</a>
              <a href={RichLink(story.success_story_campaign)} title="Visit campaign" className="kb__button--secondary kb__button--large">Visit campaign</a>
            </div>

          }

          {story.success_story_testimonial &&

            <div className="kb__col-12 kb__success-story-quote">
              {RichText.render(story.success_story_testimonial)}
            </div>
          }


        {story.body[0].fields &&
          <div className="kb__col-12 kb__success-story-stats">
            <div className="kb__grid-center">
              {story.body[0].fields.map((stat,i) => {
                return(
                  <div key={i} className="kb__col-4__sm-6 kb__success-story-stat">
                    <InlineSVG url={stat.stat_icon.url} />
                    {RichText.render(stat.stat_title)}
                  </div>
                )
              })}
            </div>
          </div>
        }

        </div>
      </div>

    </Layout>
  );
}

export default SuccessStory;

